angular
    .module('annexaApp')
    .component('annexaBoxAddressPaginated', {
        templateUrl: './components/common/annexa-box-address-paginated/annexa-box-address-paginated.html',
        controller: ['AnnexaObjectBoxFactory', 'AnnexaEntityFactory', 'globalModals', 'ModalService', '$rootScope', 'GlobalDataFactory','Language', 'TerritoryFactory', '$filter', '$scope', 'AnnexaFormlyFactory', 'HelperService', 'RestService', function (AnnexaObjectBoxFactory, AnnexaEntityFactory, globalModals, ModalService, $rootScope, GlobalDataFactory, Language, TerritoryFactory,$filter, $scope, AnnexaFormlyFactory, HelperService, RestService) {
            var vm = this;

            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASAddresses') {
                	var addedObjects = [];
                    var id = undefined;
                    _.forEach(args.selected, function (item) {
                		if(id){
                    		id =id+','+item.id;
                    	}else{
                    		id = item.id;
                    	}
                    	addedObjects.push(item);
                    });
                    
                    if(addedObjects && addedObjects.length > 0){
                    	vm.box.search.searchedValue = { id: id, object: addedObjects };
                        vm.box.search.completeAdd();
                    }
                    args.modal.close();
                }
            });

            this.$onInit = function() {
				if(vm.box && vm.box.tableDefinition){
	                vm.box.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(vm.box.permissions, 'view_territory', 'view_territory', vm.box.isEdit);
	                vm.languageColumn = Language.getActiveColumn();
	                vm.territoryModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.territory'").toArray();
	
	                vm.addressLanguage = 'address';
	                if (!$rootScope.app.configuration.show_long_address_language || !$rootScope.app.configuration.show_long_address_language.value)
	                	vm.addressLanguage = 'shortAddress';

	                if(!vm.box.boxTitle) {
	                    vm.box.boxTitle = 'global.territory.list.toptitle'; 
	                }
	
	                if(vm.box.search) {
						vm.box.search.searchFunc = function(val) {
                        	var filter = { name: val.val };
                            if(vm.box.search.customFilter) {
                                var pareseableCustomFilter = vm.box.search.customFilter.replace('@VAL@', val.val);
                                pareseableCustomFilter = pareseableCustomFilter.replace('@VAL@', val.val);
                                filter = JSON.parse(pareseableCustomFilter);
                            }

                            var additionalFilter = vm.box.search.additionalFilter ? vm.box.search.additionalFilter : {};
                            additionalFilter.loggedUser = $rootScope.LoggedUser.id;
                            additionalFilter.paginated = true;
	                        additionalFilter.paginatedObject = vm.box.tableDefinition.objectIdToSelectType;
	                        additionalFilter.paginatedObjectId = vm.box.tableDefinition.objectIdToAdded;
	                        additionalFilter.langColumn = Language.getActiveColumn();
                            
                            if(vm.box.search.type && vm.box.search.type == "Address") {
                            	additionalFilter.active = "1";
                            }

                            return RestService.filterData(vm.box.search.origin, vm.box.search.type, filter, additionalFilter, 0, 10, '', 3, 'box')
                                .then(function (data) {
                                    var response = [];

                                    _.forEach(data.data.content, function(value, key) {
                                        response.push({
                                            id: value.id,
                                            object: JSOG.decode(value),
                                            value: vm.box.search.processSearch(JSOG.decode(value))
                                        });
                                    });

                                    response = new SortedArray(response, 'value').sort();

                                    return response;
                                });

                        }
	                	vm.box.search.addedSelect = 'x => x.address.id';
	                    vm.box.search.origin = 'territory';
	                    vm.box.search.type = 'Address';
	                    vm.box.search.customFilter = '{ "addresslanguage1": "@VAL@", "addresslanguage2": "@VAL@", "addresslanguage3": "@VAL@"}';
	                    vm.box.search.processSearch = function (object) {
	                    	return object[vm.addressLanguage+vm.languageColumn];
	                    }
	
	                    if(!vm.box.search.completeAdd) {
	                        vm.box.search.completeAdd = function() {
	                            var newContent = [];
	                            if(vm.box.search.searchedValue && vm.box.search.searchedValue.object && Array.isArray(vm.box.search.searchedValue.object)){
	                        		_.forEach(vm.box.search.searchedValue.object, function(obj){
	                        			newContent.push({address: JSOG.decode(obj)});
	                        		});
	                        	}else{
	                    			newContent.push({address: JSOG.decode(vm.box.search.searchedValue.object)} );
	                        	}
	                            vm.box.search.searchedValue = { id: -1, value: '' };
	                    		if(vm.box.search.saveSearch) {
	                                if(vm.box.search.saveSearch) {
	                                    vm.box.search.saveSearch(newContent);
	                                }
	                            }
	                        }
	                    }
	
						vm.box.search.advancedSearch = function() {
	                        var streetTypes = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.streetTypes));
							var countries = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.countries));
			                var advancedSearchModal = {
	                            title: 'global.literals.advancedSearchTerritory',
	                            size: 'modal-lg',
	                            filter: [
						    		{ id: 'addressType', type: 'select', order: 0, label: 'global.literals.addressType', dataType: 'LOCAL', data: GlobalDataFactory.addressTypes, addAll: true, nameProperty: Language.getActiveColumn(), userChoosen: false },
									{ id: 'streetType', type: 'select', order:1, label:'global.thirds.literals.streetType', dataType: 'LOCAL', data:streetTypes, addAll: true, nameProperty: Language.getActiveColumn(), userChoosen: false },
									{ id: 'street', type: 'text', order: 2, label: 'global.thirds.literals.streetName', callAux: true, userChoosen: false },
									{ id: 'numberAddress', type: 'text', order: 3, label: 'global.thirds.literals.number', callAux: true, userChoosen: false }, 
			        				{ id: 'country', type: 'select', order: 4, label: 'global.thirds.literals.country', dataType: 'LOCAL', data: countries, addAll: true, nameProperty: Language.getActiveColumn(), userChoosen: true },
									{ id: 'state', type: 'text', order: 5, label: 'global.thirds.literals.state', callAux: true, userChoosen: true }, 
			        				{ id: 'city', type: 'text', order: 6, label: 'global.literals.city', callAux: true, userChoosen: true }, 
			        				{ id: 'postalCode', type: 'text', order: 7, label: 'global.thirds.literals.postalCode', callAux: true, userChoosen: true }, 
			        				{ id: 'pseudoVia', type: 'text', order: 8, label: 'global.thirds.literals.pseudoVia', callAux: true, userChoosen: true }, 
			        				{ id: 'numberAddress2', type: 'text', order: 9, label: 'global.thirds.literals.number2', callAux: true, userChoosen: true }, 
			        				{ id: 'bis', type: 'text', order: 10, label: 'global.thirds.literals.bis', callAux: true, userChoosen: true }, 
			        				{ id: 'bis2', type: 'text', order: 11, label: 'global.thirds.literals.bis2', callAux: true, userChoosen: true }, 
			        				{ id: 'kilometer', type: 'text', order: 12, label: 'global.thirds.literals.kilometer', callAux: true, userChoosen: true }, 
			        				{ id: 'block', type: 'text', order: 13, label: 'global.thirds.literals.block', callAux: true, userChoosen: true }, 
			        				{ id: 'portal', type: 'text', order: 14, label: 'global.thirds.literals.portal', callAux: true, userChoosen: true }, 
			        				{ id: 'stair', type: 'text', order: 15, label: 'global.thirds.literals.stair', callAux: true, userChoosen: true }, 
			        				{ id: 'floor', type: 'text', order: 16, label: 'global.thirds.literals.floor', callAux: true, userChoosen: true }, 
			        				{ id: 'door', type: 'text', order: 17, label: 'global.thirds.literals.door', callAux: true, userChoosen: true }, 
			        				{ id: 'cadastralReference', type: 'text', order: 18, label: 'global.territory.list.cadastral_reference', callAux: true, userChoosen: true }, 
			        				{ id: 'coordinateX', type: 'text', order: 19, label: 'global.territory.list.coordinateX', callAux: true, userChoosen: true }, 
			        				{ id: 'coordinateY', type: 'text', order: 20, label: 'global.territory.list.coordinateY', callAux: true, userChoosen: true }, 
			        				{ id: 'address', type: 'text', order: 21, label: 'global.territory.list.address', callAux: true, userChoosen: true }
	                            ],
	                            tableDefinition: {
	                                id: 'tableAdvancedSearchAddress',
	                                origin: 'territory',
	                                objectType: 'Address',
	                                sortName: 'address',
	                                sort: [[1, 'asc']],
	                                columns: [
	                                    { id: vm.addressLanguage+Language.getActiveColumn(), title: $filter('translate')('global.territory.list.address')},
							        	{ id: 'addressType', column: new DatabaseTranslatedParentColumn($filter, 'global.literals.addressType', Language.getActiveColumn()) }
	                                ],
	                                noLoading: true,
	                                callAuxToAdd: { paginated:true, paginatedObject:vm.box.tableDefinition.objectIdToSelectType, paginatedObjectId:vm.box.tableDefinition.objectIdToAdded,  isAdvanced:true, active:1}
	                            },
	                            multiple: true
	                        }
	                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASAddresses', advancedSearchModal);
	                    }
	                }
	
	                if(vm.box.new) {
	                	vm.box.new.newFunc = function() {
	                		var getLanguage  = function(address){
	                        	return address[vm.addressLanguage+vm.languageColumn];
	                		}
	                		var preCloseModal = function(address) {
	                            vm.box.search.searchedValue = {
	                                id: address.id, value: address[vm.addressLanguage+vm.languageColumn],
	                                object: {
	                                	id: address.id,
	                            		addresslanguage1:getLanguage(address),
	                            		addresslanguage2:getLanguage(address),
	                            		addresslanguage3:getLanguage(address)
	                                }
	                            };
	                            vm.box.search.completeAdd();
	                        }
	                		
	                		var newModal =  TerritoryFactory.newAddress(undefined, preCloseModal, true);
	                    };
	                    
	                }
	            }
			}
        }],
        bindings: {
            box: '='
        }
    })